/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Pulpmx = {
    // All pages
    'common': {
      
      ajax_loading: false,
      
      init: function() {
        // JavaScript to be fired on all pages
       
        $(document).foundation();
        
        $('.js-sponsor-deal').on('click', function(e){
          var sponsorLogo = $('.liveshow-sponsor__img', e.currentTarget).clone();
          var sponsorDeal = $('.liveshow-sponsor__deal', e.currentTarget).html();
          $('#liveshowSponsorsModal .liveshow-sponsor-modal__logo-wrap').html(sponsorLogo);
          $('#liveshowSponsorsModal .liveshow-sponsor-modal__content-wrap').html(sponsorDeal);
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      init_jwplayer: function( pPlayerID, pStreamURL ) {

        var player = jwplayer(pPlayerID);

        // Set up the player with an HLS stream that includes timed metadata
        player.setup({
          file: pStreamURL,
          width:'100%',
          height:40,
          autostart: true
        });
        player.setConfig([{
          autostart: 'true'
        }]);

        // Retrieve metadata
        player.on('meta', function(e) {
          if (e.metadata) {
            //console.log(e.metadata);
          }
        });

        // Handle reset of player at end of content
        player.on('error', function(e) {
          //console.log(e.message);
        });
      },
      
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_podcasts': {
      action_load_podcasts : 'ajax_load_podcasts',
      init: function() {
        // JavaScript to be fired on the about us page

        Pulpmx.page_template_template_podcasts.add_listeners();

      },
      add_listeners:function() {
        // handle podcast filter select
        $('.js-podcast-filter-select').change(function(){
          var value = $(this).val();
          var url = "/podcast_category/";
          if(value === 'default') {
            url = "/podcasts";
            //Pulpmx.page_template_template_podcasts.handle_load_podcasts(null, 0);
          }else{
            url = url+value;
            //Pulpmx.page_template_template_podcasts.handle_load_podcasts(value, 0);
          }
          $('.js-podcast-feed-wrap').empty();
          $('.js-pulpmx-loader').removeClass('hide');
          $('.js-podcast-load-more').addClass('hide');
          window.location = url;
        });

        // handle load more button
        $('#podcast-load-more-btn').click(function(){ 
          var podcast_category = $('#podcast-filter-select').val();
          if(podcast_category === 'default') podcast_category = null;
          var podcast_offset = $("#podcast-feed-wrap .podcast").length;
          Pulpmx.page_template_template_podcasts.handle_load_podcasts(podcast_category, podcast_offset);
        });
      },
      handle_load_podcasts:function(pPodcast_category, pOffset) {
        
        if(Pulpmx.common.loading === true){ return; }
        
        Pulpmx.common.loading = true;
        
        // if offset zero. we are loading a different category so clear podcast container before appending new ones
        if(pOffset === 0){ $('.js-podcast-feed-wrap').empty(); }

        // show the loader animation and hide the load more button while loading
        $('.js-pulpmx-loader').removeClass('hide');
        $('.js-podcast-load-more').addClass('hide');

        // setup 
        var data = {
          action: Pulpmx.page_template_template_podcasts.action_load_podcasts,
          podcast_category: pPodcast_category,
          podcast_offset: pOffset,
          podcast_load_count: pulpmx.post_count,
          current_post_id: pulpmx.post_id
        };
        
        $.get(themeajax.url, data, function(html) {

          if(html === '') {
            console.log('No Data Returned');
          }else{
            $('.podcast-feed-wrap').append(html);
          }
          
          // get the number of podcasts currently on the page
          var podcastCount = $("#podcast-feed-wrap .podcast").length;
          
          // if the offset plus the number of podcasts being loaded equals the current count on the page show the load more button again
          if(parseInt(pOffset) + parseInt(pulpmx.post_count) == podcastCount ) {
            $('.js-podcast-load-more').removeClass('hide');
          }

          // hide the loader animation
          $('.pulpmx-loader').addClass('hide');

          // set loading flag back to false
          Pulpmx.common.loading = false;

        });
      }
    },
    'tax_podcast_category': {
      init: function() {
        // JavaScript to be fired on the home page
        Pulpmx.page_template_template_podcasts.add_listeners();
      }
    },
    // Moto60
    'page_template_template_liveshow_moto60': {
      init: function() {
        // JavaScript to be fired on the home page
        Pulpmx.common.init_jwplayer('liveshow__player', 'http://45.79.92.156:61324/moto60/live/playlist.m3u8');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // PulpMX
    'page_template_template_liveshow_pulpmx': {
      init: function() {
        // JavaScript to be fired on the home page
        Pulpmx.common.init_jwplayer('liveshow__player', 'http://45.79.92.156:61324/pulpmx/live/playlist.m3u8');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Pulpmx;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
